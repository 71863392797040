import React, { createContext, useEffect, useState, useMemo } from "react";
import axios from "axios";

export const DataContext = createContext();
export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_STATIC = API_URL;
export const API_MAP = process.env.REACT_APP_GOOGLE_MAP_KEY;
console.log('API_URL', API_URL);
console.log('API_MAP', API_MAP);

const DataProvider = ({ children }) => {
  const [contenus, setContenus] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [restos, setRestos] = useState([]);
  const [events, setEvents] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   axios
  //     .all([
  //       axios.get("/api/contenus"),
  //       axios.get("/api/rooms/"),
  //       axios.get("/api/restos/"),
  //       axios.get("/api/events"),
  //     ])
  //     .then(
  //       axios.spread((contenuRes, roomRes, restoRes, eventsRes) => {
  //         setContenus(contenuRes.data);
  //         setRooms(roomRes.data);
  //         setRestos(restoRes.data);
  //         setEvents(eventsRes.data);
  //       })
  //     )
  //     .catch((error) => {
  //       console.error(
  //         "Une erreur s'est produite lors de la récupération des données:",
  //         error
  //       );
  //     });
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [roomsRes,facilitiesRes, restosRes, contenusRes,  eventsRes] = await Promise.all([
          axios.get(API_URL + "/api/rooms/"),
          axios.get(API_URL + "/api/facilities/"),
          axios.get(API_URL + "/api/restos/"),
          axios.get(API_URL + "/api/contenus"),
          axios.get(API_URL + "/api/events"),
        ]);

        setRooms(roomsRes.data);
        setFacilities(facilitiesRes.data);
        setRestos(restosRes.data);
        setContenus(contenusRes.data);
        setEvents(eventsRes.data);

        setIsLoading(false);

      } catch (error) {
        console.error("Une erreur s'est produite lors de la récupération des données:", error);
      }
    };

    fetchData();
  }, []);

  const memoizedValue = useMemo(
    () => ({ contenus, rooms, facilities, restos, events, isLoading }),
    [contenus, rooms, facilities, restos, events,isLoading]
  );
  return (
   !isLoading && (<DataContext.Provider value={memoizedValue}>
      {children}
    </DataContext.Provider>)
  );
};

export default DataProvider;
