import React, { Fragment, useState, useRef, useContext } from "react";
import { DataContext } from "../../context/DataContext";
import {API_URL_STATIC} from "../../context/DataContext";
import ReactHtmlParser from 'html-react-parser';
import { Link } from "react-router-dom";
//Component:
import BannerSlide from "../../components/Header/BannerSlide";
import IntroHead from "../../components/Header/IntroHead";
//MUI tailwind
import { Card, CardHeader, CardBody, CardFooter, Typography, Button,} from "@material-tailwind/react";
//image::
import Headlogo from '../../assets/static/img/logo-ankerana.png';
import CardFacilities from "../../components/Cards/CardFacilities";



const Evenement = () => {
  //States:
  const { contenus, events,facilities } = useContext(DataContext);
   // Structuration des contenus
   const content = contenus.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {}); 
  // Arrays & Object:
  const eventsItems = events.map((item) => {
    // const defaultEventPhoto = item.eventPhoto.find((photo) => photo.default);
    // const defaultImage = defaultEventPhoto.image;
    return {
      id: item.id,
      name: item.name,
      img: item.image,
      description: item.description,
    };
  });
  const [open, setOpen] = useState(1);
  const [src, setSrc] = useState(eventsItems[2]);
  //Methodes:
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const hanadlerClick = (i) => {
    setSrc(eventsItems[i]);
  };

  //ScrolClick Button
  const myRef = useRef(null);
  const scrollClick = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };
  //BannerSlide data ::
  const eventImages = events.flatMap((event) =>
    event.eventPhoto
      .filter((photo) => photo.default === true)
      .map((photo) => photo.image)
  );
  //Text
  const bannerTextEvent = { title: content[38].texte,};
  const dataTextIntroEvent = {
    introImg: eventImages[0],
    introTitle: content[40].texte,
    introSubTitle: content[39].texte,
    subTitle: content[41].texte,
    introParagh: content[42].texte,
  };
  const titleServiceEvent = content[43].texte;
  const paraghServiceEvent = content[44].texte;

  const detailsFacilitiesEvents = facilities
  .filter((faEvent) => faEvent.event == true)
  .sort((a, b)=> a.importance - b.importance)
  .map( facility => ( {
    ico: facility.icon,
    name: facility.name,
    nom:facility.nom,
  }));

  return (
    <Fragment>
      {/* Banner Slide */}
      <BannerSlide  homeImages={eventImages}  bannerText={bannerTextEvent}  scrollClick={scrollClick} />
      {/* INTRODUCTION */}
      <IntroHead data={dataTextIntroEvent} myRef={myRef} />
      {/* EVENT CARDBOX */}
      <section className="gray-background py-10">
        <div className="text-center py-10">
          {/* Logos */}
          <div className="flex justify-center ">
            <div className="w-32 h-32 bg-white shadow-xl p-4">
              <img className="" src={Headlogo} alt="headlogo" />
            </div>
          </div>
          {/* Title */}
          <h1 className="mt-3 lg:title-text font-playfair">
            {titleServiceEvent}
          </h1>
          {/* Facilities */}
          <div className="flex justify-center" >
            <CardFacilities data={detailsFacilitiesEvents} />
          </div>
          <hr className="mt-5 pb-1 borders bg-rose-700 h-full mx-auto w-[150px]" />
          {/* SubTitle */}
          <p className="mt-5 text-lg text-gray-500 mx-auto text-center lg:w-2/5">
            {paraghServiceEvent}
          </p>
        </div>

        <div ref={myRef} className="lg:py-8"></div>
        {/* CARDBOX */}
        <div className="auto-container">
          <div className=" mt-10 p-6 grid grid-cols-1 lg:grid-cols-3 gap-4">
            {eventsItems.map((itemCard) => {
              const MAX_WORDS = 20; 
              const displayedDescription = itemCard.description
                .split(" ")
                .slice(0, MAX_WORDS)
                .join(" ")
                .concat(
                  itemCard.description.split(" ").length > MAX_WORDS
                    ? "..."
                    : ""
                );
              return (
                <Card key={itemCard.id} className="w-90 rounded up-effect">
                  <CardHeader color="blue" className="relative h-56">
                    <img
                      src={API_URL_STATIC + itemCard.img}
                      alt="img-blur-shadow"
                      className="h-full w-full rounded-t-lg"
                    />
                  </CardHeader>
                  <CardBody className="text-center min-h-[187px]">
                    <Typography variant="h5" className="mb-2">
                      {itemCard.name}
                    </Typography>
                    <Typography>
                    {ReactHtmlParser(displayedDescription)}
                    </Typography>
                  </CardBody>
                  <CardFooter
                    divider
                    className="flex items-center justify-between py-3"
                  >
                    <Typography variant="small">{""}</Typography>
                    <Link to={`/evenement/${itemCard.name}/${itemCard.id}`}>
                      <Button className="rounded-none bg-rose-700">
                        Details
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              );
            })}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Evenement;
