import React,{useContext, useEffect , useState} from 'react';
import { Outlet } from 'react-router-dom';
import { DataContext } from './context/DataContext';
//Components 
import NavBar from './components/Navbar/NavBar';
import Footer from './components/Footer/Footer';
import Loader from './components/SectionContent/Loader';
import ScrollTopButton from './components/Items/ScrollButton';
import ScrollToTop from './components/Items/ScrollToTop';

const App = () => { 
const { isLoading } = useContext(DataContext);
  return (
    <div className="relative">
      {!isLoading ? (
        <>
          <NavBar />
          <ScrollTopButton />
          <ScrollToTop/>
          <main>
           <Outlet />
          </main>
          <Footer/>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};


export default App;
