import React , {Fragment, useContext, useState} from 'react';
import {DataContext} from '../../context/DataContext';
import { API_URL_STATIC } from '../../context/DataContext';
import { useParams, useNavigate } from 'react-router-dom';
import BreadCrumbs from '../../components/BreadCrumbs';
import ReactHtmlParser from 'html-react-parser';
//icon
import renderIcon from '../../components/Items/renderIcon';
import { BsArrowsFullscreen, BsPeople, BsArrowLeft} from 'react-icons/bs';
import {IoBed} from 'react-icons/io5';
import {Button} from "@material-tailwind/react";
//Component
import AccordionBox from '../../components/Items/AccordionBox'
//context
import ScrollToTop from '../../components/Items/ScrollToTop';
import { MoonLoader } from 'react-spinners';

const RoomDetails = () => {

  const { contenus,rooms } = useContext(DataContext);
  const [src, setSrc] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const content = contenus.reduce((acc, item)=>{
    acc[item.id] = item;
    return acc;
  }, {});
  //Loader wait api:
  let room = rooms.length > 0 ? rooms.find((room) => room.id === Number(id)) : null;
  if (!room) {
    return (
      <div className="loader gray-background">
        <ScrollToTop />
        <MoonLoader color="red" />
      </div>
    );
  }
  const {image, literie,categorie, maxPerson, price, description,detail, roomFacility, roomPhoto, size, type  } = room || {};
 //Pictures:
  const detailsItems = roomPhoto.map((photo) => ({
    id: photo.id,
    name: photo.name,
    img: photo.image,
    default: photo.default,
  }));
    //initialise DefaultPhoto:
    let defaultPhoto = detailsItems.find((photo) => photo.default);
    if (!defaultPhoto) {
      defaultPhoto = detailsItems.length > 0 ? detailsItems[0] : null;
    }
    //methode:
    const hanadlerClick = (i)=>{
        const selectedPhoto = detailsItems.find((photo) => photo.id === i);
        setSrc(selectedPhoto ? selectedPhoto.img : null);
    };

    const titleDetails = 'Plus de détails';

  return (
    <Fragment>
      <ScrollToTop />
      <header>
        {/* HEAD PAGE */}
        <div
          className="banner-config"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url(${image})`,
          }}
        >
          {/* colone-1  */}
          <div className="auto-container flex justify-center items-center w-full h-full ">
            {/* Title Cover*/}
            <div className="text-white text-center font-medium">
              <h1 className="font-playfair lg:title-text max-md:text-[36px]  font-bold drop-shadow-lg shadow-black">
                {content[25].texte}
              </h1>
            </div>
          </div>
        </div>
      </header>
      <div className="auto-container">
        <BreadCrumbs />
      </div>
      <section className="py-20 font-poppins gray-background dark:bg-gray-800">
        <div className="max-w-6xl px-4 mx-auto ">
          <div className="box-responsive mb-24 -mx-4 bg-white max-md:container max-md:mx-auto card-shadow">
            {/* BOX LEFT IMAGE */}
            <div className="px-3">
              <div className="mt-3 lg:h-[500px] overflow-hidden">
                <img
                  src={src || API_URL_STATIC + defaultPhoto.img}
                  alt=""
                  className="img-content"
                />
              </div>

              <div className="flex flex-wrap">
                {detailsItems.map((detail) => {
                  return (
                    <div
                      key={detail.id}
                      className="w-4/12 h-[100px] p-2 short-up-effect"
                    >
                      <img
                        src={API_URL_STATIC + detail.img}
                        alt={`${detail.name}`}
                        className="img-default"
                        onClick={() => {
                          hanadlerClick(detail.id);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            {/* BOX RIGHT TEXT */}
            <div className="w-full px-4">
              <div className="lg:pl-16 ">
                <div className=" w-full mt-6 ">
                  <div className="float-right">
                    <Button
                      onClick={() => navigate(-1)}
                      size="sm"
                      variant="outlined"
                      className="flex items-center gap-2 normal-case hover:bg-red-700 hover:text-white rounded-none"
                    >
                      <BsArrowLeft className="h-5 w-5" /> Go Back
                    </Button>
                  </div>
                  <h1 className="mt-3 text-gray-900 text-3xl title-font font-semibold mb-1">
                    {categorie}
                  </h1>

                  {/* All description */}
                  <div className="py-3 mt-5">
                    <div className=" py-2 border-t border-b grid lg:grid-cols-3 grid-cols-2 gap-2 pb-2">
                      <div className="">
                        <h2 className="max-md:text-sm text-base title-font text-gray-500 tracking-widest font-medium">
                          Type de lit
                        </h2>
                        <div className="flex items-center mt-3 ">
                          <IoBed className="text-xl text-accent" />
                          <span className="ml-2">
                            {" "}
                            <h3>{literie}</h3>
                          </span>
                        </div>
                      </div>
                      <div className="">
                        <h2 className="max-md:text-sm text-base title-font text-gray-500 tracking-widest font-medium">
                          Taille
                        </h2>
                        <p className="flex items-center mt-3 ">
                          <BsArrowsFullscreen className="text-xl text-accent" />
                          <h3 className="ml-2">{size} m2</h3>
                        </p>
                      </div>
                      <div className="">
                        <h2 className="max-md:text-sm text-base title-font text-gray-500 tracking-widest font-medium">
                          Capacite
                        </h2>
                        <p className="flex items-center mt-3 ">
                          <BsPeople className="text-xl text-accent" />{" "}
                          <h3 className="ml-2">{maxPerson} pers</h3>
                        </p>
                      </div>
                    </div>
                    <div className="py-2 ">
                      <p className="text-lg text-gray-500 leading-relaxed">
                        {ReactHtmlParser(description)}
                      </p>
                    </div>
                  </div>
                  {/* Details */}
                  <div className='border-t'>
                    <AccordionBox detail={detail} title={titleDetails} />
                  </div>
                  {/* grid faciliti */}
                  <div className="mt-3 border-b pb-5">
                    <h3 className="text-2xl font-semibold">Room Facilities</h3>
                    <div className="mt-5 grid grid-cols-2 gap-6  ">
                      {roomFacility.map((facility) => {
                        return (
                          <div
                            className="flex items-center gap-x-3 flex-1"
                            key={facility.id}
                          >
                            <div className="text-3xl text-accent">
                              {renderIcon(facility.icon)}
                            </div>
                            <div className="text-base">{facility.nom}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {/* PRICE */}
                  <div className="mt-3 py-3">
                    <h2 className="lg:text-base text-sm text-gray-500 tracking-widest font-medium">
                      Prix par nuit
                    </h2>
                    <p className="inline-block mt-3 text-3xl font-bold dark:text-gray-400 ">
                      <span>€ {price}</span>
                    </p>
                  </div>
                  {/* Reserver Button */}
                  <div className="lg:float-right lg:mr-3 pb-5">
                    <button className="max-md:mx-auto  py-5 px-12 capitalize font-semibold mt-10 btn bg-rose-700 hover:bg-rose-600 duration-300">
                      Reserver
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default RoomDetails;
