//React Router
import { createBrowserRouter } from "react-router-dom";
//Pages
import App from '../App';
import ErrorPage from '../pages/404-error/ErrorPage';
import Home from '../pages/Home';
import Chambres from '../pages/Hebergements/Chambres';
import ListChambre from '../pages/Hebergements/ListChambre';
import RoomDetails from '../pages/Hebergements/RoomDetails';
import Restaurant from '../pages/Restaurants/Restaurant';
import RestoDetails from '../pages/Restaurants/RestoDetails';
import Evenement from '../pages/Evenements/Evenement';
import EventDetails from '../pages/Evenements/EventDetails';
import Contact from '../pages/Contact';

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    //::children::// 
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/hebergement",
        element: <Chambres />,
      },
      {
        path: "/hebergement/room/:id",
        element: <RoomDetails />,
      },
      {
        path: "/hebergement/categorie-chambres",
        element: <ListChambre />,
      },
      {
        path: "/restaurant",
        element: <Restaurant />,
      },
      {
        path: "/restaurant/:name/:id",
        element: <RestoDetails />,
      },
      {
        path: "/evenement",
        element: <Evenement />,
      },
      {
        path: "/evenement/:name/:id",
        element: <EventDetails />,
      },

      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
  ]);
