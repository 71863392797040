import React from 'react';
import {FaCoffee,FaHotdog,FaStopwatch,FaBath,FaWifi,FaSwimmingPool,FaParking,FaCocktail,FaShuttleVan} from 'react-icons/fa';
import {FaEnvelope, FaPhone, FaAt, FaTv } from 'react-icons/fa';

const renderIcon = (icon, iconSize) => {

  iconSize = (iconSize ? iconSize : "0.75em")
  console.log('icon :', icon, iconSize);
  switch (icon) {
    case "FaCoffee":
      return <FaCoffee size={iconSize} />;
    case "FaCocktail":
      return <FaCocktail size={iconSize} />;
    case "FaShuttleVan":
      return <FaShuttleVan size={iconSize} />;
    case "FaWifi":
      return <FaWifi size={iconSize} />;
    case "FaStopwatch":
      return <FaStopwatch size={iconSize} />;
    case "FaHotdog":
      return <FaHotdog size={iconSize} />;
    case "FaBath":
      return <FaBath size={iconSize} />;
    case "FaTv":
      return <FaTv size={iconSize} />;
    case "FaSwimmingPool":
      return <FaSwimmingPool size={iconSize} />;
    case "FaParking":
      return <FaParking size={iconSize} />;
    case "FaAt":
      return <FaAt style={{color:"#bf4040"}} size={iconSize} />;
    case "FaEnvelope":
      return <FaEnvelope style={{color:"#bf4040"}} size={iconSize} />;
    case "FaPhone":
      return <FaPhone style={{color:"#bf4040"}} size={iconSize} />;
    default:
      return null;
  }
};

export default renderIcon;