import React from 'react';
import { API_URL_STATIC } from '../../context/DataContext';
//Swiper data
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectFade , Autoplay} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';


const HeadSlide = (props) => {
  const { contenus, restos, rooms } = props;
  //##Slides Image:##//
  //--Image Rooms::
  const homeRoomImages = rooms
    .flatMap((room) => room.roomPhoto)
    .filter((roomPhoto) => roomPhoto.home === true)
    .map((roomPhoto) => roomPhoto.image);
  //--Images Restos::
  const homeRestoImage = restos
    .flatMap((resto) => resto.restoPhoto)
    .filter((restoPhoto) => restoPhoto.home === true)
    .map((restoPhoto) => restoPhoto.image);
  //--Arrays structure
  const bannerImages = [...homeRoomImages, ...homeRestoImage];
  const shuffledBannerImages = bannerImages.sort(() => Math.random() - 0.5);
  //--Silder Data::
  const slides = shuffledBannerImages.map((image) => ({ slider: image }));
  //Contenus
  const content = contenus;
  content.sort((a, b) => a.id - b.id);
  //filter text  Id
  const contentTexte = (id) => {
    for (let i = 0; i < content.length; i++) {
      if (content[i].id === id) {
        return content[i].texte;
      }
    }
  };
  const bannerTopTitle = contentTexte(7);
  const banneMiddleTitle = contentTexte(8);
  const bannerBottomTitle = contentTexte(9);

  return (
    <>
    <Swiper
      modules={[Autoplay, EffectFade]}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      effect={"fade"}
      className="h-[500px] h-fulls bg-slate-100 m-auto group border-b-2 border-red-50"
    >
      {slides.map((slide, index) => {
        return (
          <SwiperSlide key={index}>
            <div
              style={{ backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url(${API_URL_STATIC + slide.slider})`}}
              className="w-full h-full object-cover bg-center bg-cover duration-500"
            >
              {/* Title & Parag */}
              <div className="container mx-auto w-fulls relative inset-y-1/4">
                <div className="text-center text-white py-8">
                  <h2 className="text-2xl capitalize  font-roboto">
                    {bannerTopTitle}
                  </h2>
                  <h1 className="font-playfair md:title-text text-[40px] ">
                    {banneMiddleTitle}
                  </h1>
                  <p className="text-2xl font-roboto">{bannerBottomTitle}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
    </>
  );
}

export default HeadSlide