import React from 'react';
import { API_URL_STATIC } from '../../context/DataContext';
import { Link } from 'react-router-dom';
//Component
import CardFacilities from '../Cards/CardFacilities';


const GalleryBox = (props) => {
  const { content, rooms, facilities, restos, events } = props;
  //images::
  
  const homeRoomImages = rooms
    .flatMap((room) => room.roomPhoto)
    .filter((roomPhoto) => roomPhoto.home === true)
    .map((roomPhoto) => roomPhoto.image);

  const galleryRoom = homeRoomImages[0];

  const homeRestoImages = restos
    .flatMap((resto) => resto.restoPhoto)
    .filter((restoPhoto) => restoPhoto.home === true)
    .map((restoPhoto) => restoPhoto.image);

  const galleryRestos = homeRestoImages[0];

  const homeEventImages = events
    .flatMap((event) => event.eventPhoto)
    .filter((eventPhoto) => eventPhoto.default === true)
    .map((eventPhoto) => eventPhoto.image);
  const galleryEvent = homeEventImages[0];

  //contenus text::
  const titleServiceOffer = content[14].texte;
  const paraghServiceOffer = content[15].texte;
  //Hebergement contenu :
  const titleHosting = content[17].texte;
  const subTitleHosting = content[16].texte;
  const paraghHosting = content[18].texte;
  //Classes :
  const titleHostingClass = content[17].classe;
  const subTitleHostingClass = content[16].classe;
  const paraghHostingClass = content[18].classe;

  //Restaurant contenu :
  const titleResto = content[20].texte;
  const subTitleResto = content[19].texte;
  const paraghResto = content[21].texte;
  //Evenement contenu :
  const titleEvent = content[23].texte;
  const subTitleEvent = content[22].texte;
  const paraghEvent = content[24].texte;
  //facilitices dataHome:
  const detailsFacilities = facilities.map((facility) => ({
    importance: facility.importance,
    name: facility.name,
    ico: facility.icon,
    price: facility.price,
    nom: facility.nom
  }));

  return (
    <>
      <div className="container mx-auto flex flex-col gap-3">
   
        {/* Text & titre & mini Paragh*/}
        <div className="mt-3 pb-8 text-center h-full">
          {/* Title */}
          <h2 className="lg:h2 h3 mt-3 font-playfair">{titleServiceOffer}</h2>
         {/* Facilitices */}
          <div className="flex justify-center">
              <CardFacilities data={detailsFacilities} />
          </div>
          <hr className="mt-5 mx-auto bg-rose-700 h-1 w-[150px]" />
          {/* SubTitle */}
          <p className="mx-auto mt-3 lg:text-lg text-gray-500 text-center lg:w-3/5">
            {paraghServiceOffer}
          </p>
        </div>
        {/* ***GALLERY BOX*** */}
        <div className="flex flex-col bg-white drop-shadow-md ">
          {/* Hebergement */}
          <div className=" md:flex max-md:mb-3 ">
            {/* TEXT */}
            <div className="w-full m-h-[478px] h-full">
              <div className="text-box">
                <div className={`${subTitleHostingClass}`}>
                  {subTitleHosting}
                </div>
                <h4 className={`${titleHostingClass} font-playfair`}>{titleHosting}</h4>
                <div className="py-5">
                  <p className={`${paraghHostingClass}`}>{paraghHosting}</p>
                </div>
                <div className="pt-8">
                  <Link className="" to={"/hebergement"}>
                    <button className="py-4  btn btn-primary bg-rose-700  hover:bg-rose-600">
                      Decouvrir
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            {/* IMAGES */}
            <div className="w-full h-auto overflow-hidden">
              <img
                className=" w-full h-full object-cover  hover:scale-110 transition duration-300 ease-in-out "
                src={API_URL_STATIC + galleryRoom}
                alt=""
              />
            </div>
          </div>

          {/* Restaurant */}
          <div className="md:flex ">
            <div className="w-full overflow-hidden">
              <img
                className="h-full object-cover hover:scale-110 transition-all duration-300 ease-in-out "
                src={API_URL_STATIC + galleryRestos}
                alt=""
              />
            </div>
            <div className="w-full m-h-[478px] h-full">
              <div className="text-box">
                <div className=" text-rose-700 font-medium">
                  {subTitleResto}
                </div>
                <h4 className="font-playfair text-[40px] ">{titleResto}</h4>
                <div className="py-5">
                  <p className="text-gray-500">{paraghResto}</p>
                </div>
                <div className="pt-8">
                  <Link className="" to={"/restaurant"}>
                    <button className="py-4  btn btn-primary bg-rose-700  hover:bg-rose-600">
                      Decouvrir
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Evenement */}
          <div className="md:flex  max-md:border-t-2 ">
            {/* TEXT */}
            <div className="w-full m-h-[478px] h-full">
              <div className="text-box">
                <div className=" text-rose-700 font-medium">
                  {subTitleEvent}
                </div>
                <h4 className="font-playfair text-[30px] ">{titleEvent}</h4>
                <div className="py-5">
                  <p className="text-gray-500">{paraghEvent}</p>
                </div>
                <div className="pt-8">
                  <Link className="" to={"/evenement"}>
                    <button className="py-4  btn btn-primary bg-rose-700  hover:bg-rose-600">
                      Decouvrir
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            {/* IMAGES */}
            <div className="w-full h-auto overflow-hidden">
              <img
                className="img-content ease-in-out "
                src={API_URL_STATIC + galleryEvent}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GalleryBox;