import React from 'react';
import { API_URL_STATIC } from '../../context/DataContext';
//Swiper & Modules
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectFade , Autoplay} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import BreadCrumbs from '../BreadCrumbs'

const BannerSlide = (props) => {
    const { homeImages, bannerText} = props;
  return (
    <>
      <Swiper
        modules={[Autoplay, EffectFade]}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        effect={"fade"} >
     
      {homeImages.map((itemImage, index)=>{
          return (
            <SwiperSlide key={index}>
              <div
                className="banner-config"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url(${API_URL_STATIC + itemImage})`,
                }}
              >
                {/* colone-1  */}
                <div className="container mx-auto flex items-center w-full h-full">
                  {/* Title & Parg & presntion */}
                  <div className="mx-auto text-white font-medium">
                    <h1 className="banner-title font-playfair">
                      {bannerText.title}
                    </h1>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
      })}
  
      </Swiper>
      <div className='auto-container'>
        <BreadCrumbs/>
      </div>
    </>

  );
}

export default BannerSlide