import React, {useContext} from 'react';
// room context
// headless ui menu
import {Menu} from '@headlessui/react';
//icon
import { BsChevronDown } from 'react-icons/bs';

const lis = [
  { name: "1 adult" },
  { name: "2 adults" },
  { name: "3 adults" },
  { name: "4 adults" },
];

const AdultsDropdown = ({adults , setAdults}) => {
  
  return (
    <Menu as="div" className="w-full h-full bg-white relative">
      {/* btn */}
      <Menu.Button className="w-full h-full flex items-center justify-between px-8">
        {adults}<BsChevronDown className="text-rose-700 text-base" />
      </Menu.Button>
      {/* items */}
      <Menu.Items className="bg-white absolute w-full flex flex-col z-40" as="ul">
        {lis.map((li, index) => {
          return (
            <Menu.Item
              key={index}
              onClick={() => setAdults(li.name)}
              as="li"
              className="border-b last-of-type:border-b-0 h-12 hover:bg-rose-700 hover:text-white w-full flex justify-center items-center cursor-pointer "
            >
              {li.name}
            </Menu.Item>

          );
        })}
      </Menu.Items>
    </Menu>
  );
};

export default AdultsDropdown;
