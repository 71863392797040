import React,{useState, useEffect} from 'react';
//icon
import renderIcon from '../../components/Items/renderIcon';
import {FaCoffee,FaDoorClosed, FaCheckCircle} from 'react-icons/fa';
import ReactHtmlParser from 'html-react-parser'
import { BsPeople,} from 'react-icons/bs';
import {RxDimensions} from 'react-icons/rx'
import {IoBed, IoBedSharp} from 'react-icons/io5';
import { Button,  Radio } from '@material-tailwind/react'

const CategorieRoom = (props) => {
  const { rooms, dataRooms,startDate,endDate} = props ;
  const [selectedOption, setSelectedOption] = useState(1);

  // Création d'un état pour stocker les données mises à jour de 'rooms'
  const [updatedRooms, setUpdatedRooms] = useState([]);
  // Mise à jour les données de 'rooms' en associant les valeurs de 'dataRooms'
  useEffect(() => {
    const updatedData = rooms.map(room => {
      const matchingDataRoom = dataRooms.find(dataRoom => dataRoom.categorie === room.categorie);
  
      if (matchingDataRoom) {
        return { ...room, ...matchingDataRoom };
      }
      return room;
    });
  
    setUpdatedRooms(updatedData);
  }, []);

  const calculateTotalDays = (price) => {
    
    if (startDate && endDate) {
      const nights = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
      const ratePerNight = price;
      const totalAmount = nights * ratePerNight;
      return totalAmount;
    }
    return 0;
  };
  const TABLE_HEAD = ["Type de logement", "Vos options", "le nombre de chambre", "Tarif pour la nuit",""];

  return (
    <section className="py-3 h-full ">
      <div className="mt-20 grid grid-cols-1 p-3">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="relative w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="sticky top-0 text-sm text-gray-700  dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="w-52 border px-6 py-3 bg-gray-50 dark:bg-gray-800"
                >
                  Type de logement
                </th>

                <th scope="col" className="w-60 border px-6 py-3  bg-gray-50">
                  Vos options
                </th>

                <th scope="col" className="w-auto border px-6 py-3 bg-gray-50">
                  Sélectionner le nombre de chambre
                </th>

                <th scope="col" className="w-40 border px-6 py-3 bg-gray-50">
                  Tarif pour la nuit
                </th>

                <th
                  scope="col"
                  className=" border-r border-t  px-6 py-3  bg-gray-50"
                ></th>
              </tr>
            </thead>

            <tbody>
              {updatedRooms?.map((room) =>
                room.categorie !== room.categorie ? null : (
                  <tr
                    key={room.id}
                    className=" border-b-2 border-gray-200 dark:border-gray-700"
                  >
                    {/* Type de logement */}
                    <th
                      scope="row"
                      className="border-x px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
                    >
                      <h1 className="h3 underline">{room.categorie}</h1>
                      <div className="py-5 ml-3">
                        <p className="text-baseo font-semibold">
                          Choisissez votre lit (sous réserve de disponibilité)
                        </p>
                        {/* Literie */}
                        <div className="">
                          <div className="py-2 flex items-center">
                            <p className="ml-2">{room.literie}</p>
                            <span className="text-2xl ml-2">
                              <IoBed />
                            </span>
                          </div>
                          {/* <div className="flex items-center">
                          <Radio
                            id="html"
                            name="type"
                            label="1 grand lit double"
                          />
                          <span className="text-4xl ml-3">
                            <IoBedSharp />
                          </span>
                        </div> */}
                        </div>

                        <div className="mt-3 ml-3">
                          <div className="flex items-center">
                            <RxDimensions className="text-base" />
                            <p className="ml-2">{room.size} m²</p>
                            <FaDoorClosed className="text-xl ml-3" />
                            <p className="ml-2"> : {room.type}</p>
                            <BsPeople className="ml-3 text-lg" />
                            <p className="ml-2">
                              :{" "}
                              <span className="font-bold">
                                {room.maxPerson}{" "}
                              </span>
                              Personnes
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="border-t py-3">
                        {room?.roomFacility?.map((item, index) => {
                          return (
                            <div className="ml-3">
                              <div key={index} className="border-0">
                                <p className="font-semibold">
                                  {item.rubrique} :
                                </p>
                                <p className="ml-3 py-2 flex items-center">
                                  <FaCheckCircle color="green" />{" "}
                                  <span className="ml-2">{item.nom}</span>
                                  <span className="ml-2 text-xl">
                                    {renderIcon(item.icon)}
                                  </span>
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </th>

                    {/* Vos option */}
                    <td className="border-x">
                      {/* option petit dejeuner */}
                      <div className="py-3 border-b ">
                        {room?.roomFacility?.map((item) =>
                          item.price !== null ? (
                            <div key={item.nom} className="ml-3 text-black">
                              <span className="flex items-center">
                                <FaCoffee className="text-xl mr-2" />{" "}
                                Petit-déjeuner à € {item.price * selectedOption}
                              </span>
                              <div className="py-3">
                                <p className="flex ml-6">
                                  Nombre de personne :
                                  <select
                                    className="ml-2"
                                    name=""
                                    id=""
                                    value={selectedOption}
                                    onChange={(e) =>
                                      setSelectedOption(
                                        parseInt(e.target.value)
                                      )
                                    }
                                  >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </select>
                                </p>
                              </div>
                            </div>
                          ) : null
                        )}
                      </div>

                      <div className="py-3 px-3">
                        <p className="text-green-600">
                          <span className="flex items-center">
                            Petit-déjeuner compris
                          </span>
                        </p>
                      </div>
                    </td>
                    {/* selec des chambres */}
                    <td className="py-4">
                      <div className="pl-3 py-2 border-b">
                        <select
                          className="w-30 h-8 appearence-none  px-4 text-gray-700 text-base font-normal border border-gray-200 rounded-lg "
                          name=""
                          id=""
                        >
                          <option>0</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                        </select>
                      </div>
                      <div className="pl-3 py-2 ">
                        <select
                          className="w-30 h-8 appearence-none  px-4 text-gray-700 text-base font-normal border border-gray-200 rounded-lg "
                          name=""
                          id=""
                        >
                          <option>0</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                        </select>
                      </div>
                    </td>
                    {/* tarif */}
                    <td className="border-x py-4">
                      <div className="p-3">
                        <p className="text-black font-semibold">
                          {" "}
                          € {room.price}
                        </p>
                        <p className="py-2">+ Taxes et frais compris</p>
                      </div>
                      <div>
                        <p>Total amount: {calculateTotalDays(room.price)}€</p>
                      </div>
                    </td>
                    {/* boutton de validation */}
                    <td className="border-x px-2 py-4 text-center  bg-gray-50">
                      <Button
                        size="md"
                        className="btn-red normal-case rounded-none"
                      >
                        Je réserve
                      </Button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default CategorieRoom;