import React, {Fragment, useState, useContext} from 'react';
//Context
import ScrollToTop from '../../components/Items/ScrollToTop';
import { useLocation } from "react-router-dom";
//Components
import Rooms from '../../components/RoomsModels/Rooms';
import BookForm from '../../components/Forms/BookForm';
import CoverHead from '../../components/Header/CoverHead';
import CategorieRoom from '../../components/RoomsModels/CategorieRoom';
import { DataContext } from '../../context/DataContext';

const ListChambre = () => {
  const location = useLocation();
  const {rooms} = useContext(DataContext);
  const [roomsPost, setRoomsPost] = useState(location.state?.roomsPost || []);
  const [startDate, setStartDate] = useState(location.state?.startDate || null);
  const [endDate, setEndDate] = useState(location.state?.endDate || null);
  const searchRooms = ({result}) => {
    setRoomsPost(result);
  };
  const coverContent = {
    title: "Disponibilité de nos Chambres ",
    img: "",
    color: "#f4ab91",
  };

  return (
    <Fragment>
      <ScrollToTop />
      {/* HEAD PAGE */}
      <CoverHead data={coverContent} />
      <div className='container mx-auto relative'>
        <div className="mt-4 p-4 lg:shadow-xl lg:absolute lg:left-0 lg:right-0 lg:p-0 lg:z-30 lg:-top-12">
          <BookForm searchRooms={searchRooms} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}  />
        </div>
      </div> 

        <div className="auto-container">
          <CategorieRoom rooms={roomsPost} dataRooms={rooms} startDate={startDate} endDate={endDate} />
       </div>
    </Fragment>
  );
}

export default ListChambre;