import React,{useEffect, useState, } from 'react';
import { HiPaperAirplane as TopUp } from "react-icons/hi";


const ScrollTopButton = () => {
    
    const classNames = (...classes) => {
      return classes.filter(Boolean).join(" ");
    };
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    useEffect(() => {
      window.addEventListener("scroll", toggleVisibility);

      return () => {
        window.removeEventListener("scroll", toggleVisibility);
      };
    }, []);

  return (
    <div className="fixed bottom-8 right-8 z-50">
      <button
        type="button"
        onClick={scrollToTop}
        className={classNames(
          isVisible ? "opacity-100" : "opacity-0",
          "bg-red-700 hover:bg-red-600 focus:ring-red-500 inline-flex items-center rounded-full p-3 text-white shadow-sm transition-opacity focus:outline-none focus:ring-offset-2"
        )}
      >
        <TopUp className='h-12 w-12 text-base text-white text-center aria-hidden="true"' />
      </button>
    </div>
  );
}


export default ScrollTopButton;