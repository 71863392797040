import React, {useState,useEffect} from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { API_URL } from '../../context/DataContext';
//Components
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsCalendar } from 'react-icons/bs';
import AdultsDropDown from '../Items/AdultsDropdown';
import KidsDropdown from '../Items/KidsDropdown';
import CheckOut from '../Items/CheckOut';
import CheckIn from '../Items/CheckIn'
import { ClipLoader} from 'react-spinners';


const BookForm = (props) => {
  const {searchRooms, startDate, setStartDate, endDate, setEndDate} = props;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  // const [startDate, setStartDate] = useState(location.state?.startDate || null);
  // const [endDate, setEndDate] = useState(location.state?.endDate || null);
  const [adults, setAdults] = useState(location.state?.adults || "2 Adults");
  const [children, setChildren] = useState(location.state?.children ||"0 kid");

  // Récupérer le jeton CSRF depuis le cookie envoyé par le serveur
  useEffect(() => {
    const csrftoken = getCookie("csrftoken");
    axios.defaults.headers.post["X-CSRFToken"] = csrftoken;
  }, []);
 
  const handleSubmit =(event) => {
    event.preventDefault();
    setLoading(true); // Activation du chargement
    axios
      .post(API_URL + "/api/search-rooms/", {
        start_date: startDate,
        end_date: endDate,
        adults: parseInt(adults),
        children: parseInt(children),
      })
      .then((response) => {
        searchRooms({result:response.data.dispo,startDate,endDate,adults,children});
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(()=>{
        setLoading(false); // Désactivation du chargement
      });

  }
   // Fonction utilitaire pour récupérer le jeton CSRF depuis le cookie
   function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  return (
    
    <form className="h-[300px] bg-accent/20 w-full lg:h-[70px]">
      <div className="flex flex-col w-full h-full lg:flex-row">
        <div className="flex-1 border-r">
          {/* <CheckIn /> */}
          <div className="relative flex items-center justify-end h-full">
                {/* icon */}
                <div className="absolute z-10 pr-8">
                    <div>
                    <BsCalendar className="text-rose-700 text-base" />
                    </div>
                </div>
                <DatePicker
                    className="w-full h-full"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    minDate={new Date()}
                    placeholderText="Date d'arrivée"
                    dateFormat="dd/MM/yyyy"
                />
          </div>
        </div>
        <div className="flex-1 border-r">
          {/* <CheckOut /> */}
          <div className="relative flex items-center justify-end h-full">
            {/* icon */}
            <div className="absolute z-10 pr-8">
                <div>
                <BsCalendar className="text-rose-700 text-base" />
                </div>
            </div>
            <DatePicker
                className="w-full h-full"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="Date de départ"
                dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className="flex-1 border-r">
          <AdultsDropDown adults={adults} setAdults={setAdults} />
        </div>
        <div className="flex-1 border-r">
          <KidsDropdown kids={children} setKids={setChildren} />
        </div>

        {/* btn submit */}
        <button 
          onClick={handleSubmit} 
          type="submit"   
          className={`btn btn-primary bg-rose-700  hover:bg-rose-600 font-bold uppercase ${
            loading ? 'cursor-not-allowed' : ''
          }`}
          disabled={loading}
          >
          {loading ? <ClipLoader color='#ffff' size={30} /> :'Rechercher'}
        </button>
       
      </div>
    </form>
  );
};

export default BookForm;
