import React from 'react'
const CoverHead = ({data}) => {

  return (
    <header>
    <div
      className="h-[400px] bg-[#f3f4f6] bg-cover bg-bottom"
      style={{ backgroundImage: `url(${data.img})`,backgroundColor: `${data.color}` }}
    >
      {/* colone-1  */}
      <div className="auto-container flex justify-center items-center w-full h-full">
        {/* Title Cover*/}
        <div className="text-white text-center font-medium">
          <h1 className="leading-tight lg:title-text text-4xl font-playfair font-bold drop-shadow-lg shadow-black">
            {data.title}
          </h1>
        </div>
      </div>
    </div>
  </header>
  )
}

export default CoverHead;