import React, {Fragment, useRef, useContext} from 'react';
//Context
import { DataContext } from '../../context/DataContext';
import { API_URL_STATIC } from '../../context/DataContext';
import ReactHtmlParser from 'html-react-parser';
import { Link } from 'react-router-dom';
import Headlogo from '../../assets/static/img/logo-ankerana.png';
//MUI tailwind
import { Card, CardHeader, CardBody, CardFooter, Typography, Button} from "@material-tailwind/react";
//Component
import BannerSlide from '../../components/Header/BannerSlide';
import IntroHead from '../../components/Header/IntroHead';
import CardFacilities from '../../components/Cards/CardFacilities';


const Restaurant = () => { 
  const { contenus, restos, facilities } = useContext(DataContext);
   // Structuration des contenus
   const content = contenus.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {}); 
  //CardsResto:
  const cardsRestos = restos.map((item)=>{
    //  const defaulRestoPhoto = item.restoPhoto.find((photo) => photo.default);
    //  const defaultImage = defaulRestoPhoto.image; 
    return {
    id:item.id,
    name:item.name,
    img:item.image,
    description:item.description,
  }
  });
  
   //ScrolClick Button 
  const myRef = useRef(null);
  //BannerSlide data ::
  const homeImages = restos.flatMap((resto) =>
    resto.restoPhoto
      .filter((photo) => photo.home === true)
      .map((photo) => photo.image)
  );
  //All Content Text::
  const bannerTextResto = {  title : content[31].texte,}
  const titleService = content[36].texte;
  const paraghService = content[37].texte;
  const dataTextIntroResto = {
    introImg:homeImages[0],
    introTitle: content[33].texte,
    introSubTitle:content[32].texte,
    subTitle:content[34].texte,
    introParagh:content[35].texte,
    titleService:content[36].texte,
    paraghService:content[37].texte,
  } 
  const detailsFacilitiesRestos = facilities
  .filter((faResto) => faResto.resto == true)
  .sort((a, b)=> a.importance - b.importance)
  .map( facility =>({
    ico:facility.icon,
    name:facility.name,
    importance:facility.importance,
    nom:facility.nom,
  }));



  return (
    <Fragment>
      {/* Banner Slide */}
        <BannerSlide homeImages={homeImages} bannerText={bannerTextResto}/>
      {/* INTRODUCTION  */}
      <IntroHead data={dataTextIntroResto} myRef={myRef}  />
      {/* RESTURANT CARDBOX */}
      <section  className="gray-background h-full py-24"> 
  
        <div className="text-center py-10">
          {/* Logos */}
          <div className="flex justify-center ">
            <div className="w-32 h-32 bg-white shadow-xl p-4">
              <img className="" src={Headlogo} alt="headlogo" />
            </div>
          </div>
          {/* Title */}
          <h1 className="mt-3 lg:title-text font-playfair">
            {titleService}
          </h1>
          {/* Facilities */}
        <div className="flex justify-center">
          <CardFacilities data={detailsFacilitiesRestos} />
        </div>
          <hr className="mt-5 pb-1 borders bg-rose-700 h-full mx-auto w-[150px]" />
          {/* SubTitle */}
          <p className="mt-5 text-lg text-gray-500 mx-auto text-center lg:w-2/5">
            {paraghService}
          </p>
        </div>

        <div ref={myRef} className='lg:py-8' ></div>
        {/*CARDBOX */}
        <div  className="auto-container">
          <div className=" mt-10 p-6 grid grid-cols-1 lg:grid-cols-3 gap-4">
            {cardsRestos.map((itemCard) => {
              const MAX_WORDS = 20; 
              const displayedDescription = itemCard.description
                .split(" ")
                .slice(0, MAX_WORDS)
                .join(" ")
                .concat(
                  itemCard.description.split(" ").length > MAX_WORDS
                    ? "..."
                    : ""
                );
              return (
                <Card key={itemCard.id} className="w-90 rounded up-effect">
                  <CardHeader color="blue" className="relative h-56">
                    <img
                      src={API_URL_STATIC + itemCard.img}
                      alt="img-blur-shadow"
                      className="h-full w-full rounded-t-lg"
                    />
                  </CardHeader>

                  <CardBody className="text-center min-h-[187px]">
                    <Typography variant="h5" className="mb-2">
                      {itemCard.name}
                    </Typography>
                    <Typography>{ReactHtmlParser(displayedDescription)}</Typography> 
                  </CardBody>
                  <CardFooter
                    divider
                    className="flex items-center justify-between py-3"
                  >
                    <Typography variant="small">{""}</Typography>
                    <Link to={`/restaurant/${decodeURIComponent(itemCard.name)}/${itemCard.id}`}>
                      <Button className="rounded-none btn-red">
                        Details
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              );
            })}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Restaurant;