import React,{useContext} from 'react';
import {DataContext} from '../../context/DataContext';
import { Link } from 'react-router-dom';
//logo
import logoAnkerana from '../../assets/static/img/logo-ankerana.png';
//icons
import {BsLinkedin,BsFacebook, BsInstagram} from 'react-icons/bs';

const Footer = () => {
const {contenus} = useContext(DataContext);
const content = contenus;
content.sort((a, b) => a.id - b.id);
const contentTexte = (id) => {
  for (let i = 0; i < content.length; i++) {
    if (content[i].id === id) {
      return content[i].texte;
    }
  }
};
  return (
    <footer className="bg-[#760003] max-md:hidden border-t pt-3 dark:bg-gray-900">
      <div className="container p-6 mx-auto">
        <div className="lg:flex">
          <div className="w-full -mx-6 lg:w-2/5">
            <div className="px-6">
              <div className="w-24 h-24">
                <img
                  className="w-auto bg-white object-cover"
                  src={logoAnkerana}
                  alt=""
                />
              </div>

              <div className="flex mt-6 -mx-2">
                <a
                  href="https://www.facebook.com/LaResidenceAntananarivo"
                  className="mx-2 text-white transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                  target="_blank"
                  aria-label="Facebook"
                >
                  <BsFacebook className="w-5 h-5" />
                </a>

                <a
                  href="https://instagram.com/laresidence_ankerana?igshid=NTc4MTIwNjQ2YQ=="
                  className="mx-2 text-white transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                  target="_blank"
                  aria-label="Instagram"
                >
                  <BsInstagram className="w-5 h-5" />
                </a>
              </div>
            </div>
          </div>

          <div className="mt-6 lg:mt-0 lg:flex-1">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              <div>
                <Link
                  to={"/hebergement"}
                  className="text-white font-semibold dark:text-white"
                >
                  {contentTexte(2)}
                </Link>
                <Link
                  to={"/hebergement/room/6"}
                  className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline"
                >
                  Suite de luxe
                </Link>
                <Link
                  to={"/hebergement/room/3"}
                  className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline"
                >
                  Chambre cozy
                </Link>
                <Link
                  to={"/hebergement/room/4"}
                  className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline"
                >
                  Suite vintage
                </Link>
              </div>

              <div>
                <Link
                  to={"/restaurant"}
                  className="text-white font-semibold dark:text-white"
                >
                  {contentTexte(3)}
                </Link>
                <Link
                  to={"restaurant/resto-details/Privilège/1"}
                  className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline"
                >
                  Privilège
                </Link>
                <Link
                  to={"restaurant/resto-details/Le%20Club/2"}
                  className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline"
                >
                  Le Club
                </Link>
                <Link
                  to={"restaurant/resto-details/La%20Piscine/3"}
                  className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline"
                >
                  La Piscine
                </Link>
              </div>

              <div>
                <Link
                  to={"/evenement"}
                  className="text-white font-semibold dark:text-white"
                >
                  {contentTexte(4)}
                </Link>

                <Link
                  to={"evenement/event-details/Mariage/1"}
                  className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline"
                >
                  Mariage
                </Link>

                <Link
                  to={"evenement/event-details/Séminaire/3"}
                  className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline"
                >
                  Réunions
                </Link>

                <Link
                  to={"/evenement/event-details/Banquets/2"}
                  className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline"
                >
                  Banquets
                </Link>
              </div>

              <div>
                <Link
                  to={"/contact"}
                  className="text-white font-semibold dark:text-white"
                >
                  {contentTexte(6)}
                </Link>
                <span className="block mt-2 text-sm text-white dark:text-gray-400">
                  +261 34 01 807 70
                </span>
                <span className="block mt-2 text-md text-white dark:text-gray-400">
                  hotel_laresidence@freenet.mg
                </span>
              </div>
            </div>
          </div>
        </div>

        <hr className="h-px my-6 bg-gray-200 border-none dark:bg-gray-700" />

        <div>
          <p className="text-center text-white dark:text-white">
            © La Résidence Ankerana 2023 - All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
