import React,{useState} from 'react';
import renderIcon from '../Items/renderIcon';
import { Popover,PopoverContent,Typography,PopoverHandler } from '@material-tailwind/react';



const CardFacilities = ({data}) => {

  const [openPopover, setOpenPopover] = useState(false);

  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => setOpenPopover(false),
  };
  return (
    <section className="py-3">
      <div className="auto-contaier">
        <div className="grid grid-rows-1">
          {/* Facilities Box */}
          <div className="grid grid-cols-4 max-md:auto-container lg:flex lg:flex-nowrap gap-2">
            {data.map((item, index) => (
              <div key={index} className="flex items-center lg:w-auto h-auto">
                <div className="max-md:mx-auto">
                  <div className="p-3">
                    <div className="relative group  w-[60px] h-[60px] bg-white box-border shadow-md flex justify-center items-center">
                      <div className="p-3 text-2xl text-red-700 hover:cursor-pointer">
                        <div className=' flex justify-center'>
                          {renderIcon(item.ico)}
                        </div>
                        <p className="w-auto text-xs p-reponsive text-black font-playfair font-semibolds mt-2">
                          {item.name}
                        </p>
                      </div>
                      {/* Facility Nom */}
                      <div className='invisible group-hover:visible opacity-0 group-hover:opacity-100 transition absolute top-full mt-2 ' >
                          <div className='bg-white whitespace-nowrap p-2 rounded' >
                              <p className='text-sm font-semibold' >{item.nom}</p>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CardFacilities;