import React, {Fragment,useRef, useContext} from 'react';
import {DataContext} from '../../context/DataContext' ;
import { API_URL_STATIC } from '../../context/DataContext';
//Images
import Headlogo from '../../assets/static/img/logo-ankerana.png';
//Components
import BannerSlide from '../../components/Header/BannerSlide';
import IntroHead from '../../components/Header/IntroHead';
import CardBox from '../../components/Cards/CardBox';
import CardFacilities from '../../components/Cards/CardFacilities';

const Chambres = () => {
  const { rooms, contenus, facilities } = useContext(DataContext);
  //ScrolClick Button
  const myRef = useRef(null);
  // Structuration des contenus
  const content = contenus.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
  // CardBox data::
  const cardsInfos = rooms
    .filter((room) => room.home === true)
    .map((room) => {
      return {
        id: room.id,
        price: "€" + room.price + "/nuit",
        name: room.name,
        description: room.description,
        img: room.image ? room.image : null,
      };
    });

  const homeImages = rooms.flatMap((room) =>
    room.roomPhoto
      .filter((photo) => photo.home === true)
      .map((photo) => photo.image)
  );

  // Catch possible errors
  if (!cardsInfos || !content || !homeImages) {
    return <div>Chargement...</div>;
  }
  //Contenus Text Hebergement::
  const bannerRoomText = { title: content[25].texte };
  const titleServiceRooms = content[29].texte;
  const paraghServiceRooms = content[30].texte;
  const dataTextIntroRoom = {
    introImg: homeImages[0],
    introTitle: content[27].texte,
    introSubTitle: content[26].texte,
    introParagh: content[28].texte,
  };
  const detailsFacilitiesRooms = facilities
  .filter((faRoom) => faRoom.room == true)
  .sort((a, b) => a.importance - b.importance)
  .map(facility =>({
    ico:facility.icon,
    name:facility.name,
    nom:facility.nom,
  }));
console.log(detailsFacilitiesRooms)
  return (
    <Fragment>
      {/* HEAD PAGE */}
      <BannerSlide homeImages={homeImages} bannerText={bannerRoomText} />
      {/*INTRODUCTION */}
      <IntroHead data={dataTextIntroRoom} myRef={myRef} />
      {/* ROOM CARDBOX*/}
      <section className="h-full py-24 bg-[#f3f4f6]">

        {/* Text Into */}
        <div className="text-center py-10">
          {/* Logos */}
          <div className="flex justify-center ">
            <div className="w-32 h-32 bg-white shadow-xl p-4">
              <img className="" src={Headlogo} alt="headlogo" />{" "}
            </div>
          </div>
          {/* Title */}
          <h1 className="mt-3 lg:title-text font-playfair">
            {titleServiceRooms}
          </h1>
          {/* Facilities */}
        <div className="auto-container flex justify-center">
          <CardFacilities data={detailsFacilitiesRooms} />
        </div>
          <hr className="mt-5 pb-1 borders bg-rose-700 h-full mx-auto w-[150px]" />
          {/* SubTitle */}
          <p className="mt-5 text-lg text-gray-500 text-center">
            {paraghServiceRooms}
          </p>
        </div>
   
        <div ref={myRef}>{/*"MyRef Smooth"*/}</div>
        <div className="auto-container">
          <div className=" mt-20 p-6 grid grid-cols-1 lg:grid-cols-3 gap-4">
            {cardsInfos.map((itemCard, index) => {
              return (
                <div className="mx-auto up-effect">
                  <CardBox key={index} itemCard={itemCard} />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Chambres;