import React from 'react';
import { Link } from 'react-router-dom';

const MobileNav = ({contenus}) => {
  const content = contenus;
  content.sort((a, b) => a.id - b.id);
  const contentTexte = (id) => {
    for (let i = 0; i < content.length; i++) {
      if (content[i].id === id) {
        return content[i].texte;
      }
    }
  };
    const links = [
      {
        name: contentTexte(2),
        route: "/hebergement",
      },
      { name: contentTexte(3), route: "/restaurant" },
      {
        name:  contentTexte(4),
        route: "/evenement",
      },
      { name: contentTexte(6), route: "/contact" },
    ];
    return (
      <div className="bg-white w-full shadow-2xg lg:hidden md:hidden trasition ">
        <div className="">
          <div className="px-3 py-4 text-lg border-y hover:cursor-pointer hover:bg-rose-700 hover:text-white">
            <Link to={"/"}>{contentTexte(1)}</Link>
          </div>
          {links.map((link, index) => (
            <div key={index}>
              <div className="px-3 py-4 border-b text-lg text-left cursor-pointer group hover:cursor-pointer hover:bg-rose-700 hover:text-white">
                <Link to={link.route} className="">
                  {link.name}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
}
export default MobileNav;
