import React, {Fragment, useState, useEffect, useContext} from 'react';
import ReactHtmlParser from 'html-react-parser';
import { API_URL_STATIC } from '../../context/DataContext';
//Axios
import {DataContext} from '../../context/DataContext';
import { useParams, useNavigate, Link } from 'react-router-dom';
//icon:
import {BsArrowLeft} from 'react-icons/bs';
//Component
import ScrollToTop from '../../components/Items/ScrollToTop';
import { MoonLoader } from 'react-spinners';
import { Typography, Button } from "@material-tailwind/react";
import BreadCrumbs from '../../components/BreadCrumbs';

const EventDetails = () => {
  const { contenus,events } = useContext(DataContext);
  const content = contenus.reduce((acc, item)=>{
    acc[item.id] = item;
    return acc;
  }, {});

  //States:
  const [src, setSrc] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  //get event::
  let event = null;
  if (events.length) {
    event = events.find((event) => {
      return event.id === Number(id);
    });
  }
    // Utilisation de la variable `defaultPhoto` pour initialiser `src`
    useEffect(() => {
      if (event) {
        if (defaultPhoto) {
          setSrc(defaultPhoto);
        }
      }
    }, [event]);
  //Waiting loading data to mount for desctructure:
  if (!event) {
    return (
      <div className="loader gray-background">
        <MoonLoader color="red" />
      </div>
    );
  }

  //destructiring:
  const { name, description, eventPhoto, } = event;
  //Photos::
  const detailsItems = eventPhoto.map((photo, index) => ({
    id: index,
    name: photo.title,
    img: photo.image,
    default: photo.default,
  }));
  //
  const defaultPhotoItem = detailsItems.find((photo) => photo.default);
  //defaul Image::
  const defaultPhoto = defaultPhotoItem ? defaultPhotoItem.img : null;
  //methode::
  const hanadlerClick = (i)=>{
    const selectedPhoto = detailsItems.find((photo) => photo.id === i);
    setSrc(selectedPhoto ? selectedPhoto.img : null);
};

  return (
    <Fragment>
      <ScrollToTop />
      <header>
        <div className="banner-config" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url(${defaultPhoto})` } }>        
          <div className="auto-container flex justify-center items-center w-full h-full ">
            {/* Title Cover*/}
            <div className="text-white text-center font-playfair">
              <h1 className="title-details">{content[38].texte}</h1>
            </div>
          </div>
        </div>
      </header>
      <div className='auto-container' >
      <BreadCrumbs/>
      </div>
      <section className="py-10 font-poppins gray-background">
        <div className="max-w-6xl px-4 mx-auto">
          <div className="box-responsive h-full pb-10 bg-white card-shadow">
            {/* Images */}
            <div className="p-3 pb-8">
              <div className="h-[460px] overflow-hidden">
                <img src={src || API_URL_STATIC + defaultPhoto } className="img-content" />
              </div>
             {/* Gallerie Images */}
              <div className="flex flex-wrap">
                {
                  detailsItems.map((eventDetail)=>(
                    <div key={eventDetail.id} className="w-4/12 h-[100px] p-2 short-up-effect" >
                    <img
                          src={API_URL_STATIC + eventDetail.img}
                          alt={eventDetail.name}
                          className="img-default"
                          onClick={()=>{hanadlerClick(eventDetail.id)}}
                        />
                    </div>
                  ))
                }
              </div>
            </div>
            {/* information text */}
            <div className="p-3 max-md:mt-10">
            <div className="float-right">
                    <Button onClick={()=> navigate(-1)}  size='sm' variant="outlined" 
                      className="flex items-center gap-2 normal-case hover:bg-red-700 hover:text-white rounded-none">
                      <BsArrowLeft className='h-5 w-5' /> Go Back
                    </Button>
            </div>
              <div className="container mx-auto">
                <Typography variant="h2">{name}</Typography>
                <div className="mt-3">
                  <Typography variant="paragraph" color="gray">
                    {ReactHtmlParser(description)}
                  </Typography>
                </div>
              </div>
              {/* Button contact */}
              <div className='lg:mt-60 w-full h-full '>
                  <div className='lg:mr-10 py-3 lg:float-right '>
                  <Link to={'/contact'}>
                      <button className="max-md:mx-auto  py-5 px-12 capitalize font-semibold mt-10 btn bg-rose-700 hover:bg-rose-600 duration-300">
                          Contact
                      </button>
                  </Link>
                  </div>
                </div> 
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default EventDetails;
