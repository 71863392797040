import React, { useState} from "react";
import { PulseLoader } from "react-spinners";

const Loader = (props) => {
const {loading} = props;
  return (
    <div className={`fixed top-0 left-0 bottom-0 w-full h-screen z-50 overflow-hidden mx-auto bg-white ${loading ? 'opacity-100' : 'opacity-0'} transition-opacity duration-600`}>
      <div className="flex justify-center items-center h-screen">
        <PulseLoader color="hsla(351, 48%, 50%, 1)" size={20} speedMultiplier={0.5} />
      </div>
    </div>
  );
};

export default Loader;
