import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser'

//MUI tailwind
import { Card, CardHeader, CardBody, CardFooter, Typography, Button} from "@material-tailwind/react";

const CardBox = ({itemCard}) => {
  const MAX_WORDS = 30; 
  const displayedDescription = itemCard.description
    .split(" ")
    .slice(0, MAX_WORDS)
    .join(" ")
    .concat(
      itemCard.description.split(" ").length > MAX_WORDS
        ? "..."
        : ""
    );
  return (
    <Card className="w-90 rounded">
      <CardHeader color="blue" className="relative h-56">
        <img
          src={itemCard.img}
          alt="img-blur-shadow"
          className="h-full w-full rounded-t-lg"
        />
      </CardHeader>

      <CardBody className="text-center min-h-[187px]">
        <Typography variant="h5" className="mb-2">
          {itemCard.name}
        </Typography>
        <Typography>{ReactHtmlParser(displayedDescription)}</Typography>
      </CardBody>

      <CardFooter divider className="flex items-center justify-between py-3">
        <Typography variant="small">{itemCard.price}</Typography>
        <Link to={`/hebergement/room/${itemCard.id}`}>
          <Button className="rounded-none bg-rose-700">Details</Button>
        </Link>
      </CardFooter>
    </Card>
  );
}

export default CardBox