import React from 'react';
import { API_MAP } from '../../context/DataContext';

const GoogleMap = () => {

  const address = "La Résidence d'Ankerana, Tananarive";
  const center="center=-18.88733,47.55427";
  const zoom ="zoom=13"
  const key = "key=" + API_MAP;

  const mapUrl = `https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
    address
  )}&${center}&${zoom}&${key}`;

  return (
    <section className="bg-white dark:bg-gray-900  w-full">
      <div className="container px-3 py-6 mx-auto">
        <div className=''>
          <p className="font-medium text-rose-700 dark:text-blue-400">
            Découvrir la résidence
          </p>

          <h1 className="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl dark:text-white">
            Notre position
          </h1>

          <p className="mt-3 text-gray-500 dark:text-gray-400">
            Lieu bien situé pour les visiteurs. Planifier un itinéraire
          </p>
        </div>

        <div className=" grid grid-cols-1 mt-10">
          <div className="overflow-hidden rounded-lg lg:col-span-2 h-96 lg:h-auto">
            <iframe
              title="Google Maps"
              width="100%"
              height="450"
              style={{ border: 0 }}
              src={mapUrl}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GoogleMap;
