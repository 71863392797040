import React, {Fragment, useState, useEffect, useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import { API_URL_STATIC , API_URL } from '../../context/DataContext';
import { useParams ,useNavigate, Link } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';
//icons:
import {FaCocktail as Boissons} from 'react-icons/fa';
import {MdRestaurantMenu as Menu, MdLunchDining as Plat } from 'react-icons/md';
import {BsArrowLeft} from 'react-icons/bs';
//Components
import ScrollToTop from '../../components/Items/ScrollToTop';
import { Typography,Button } from "@material-tailwind/react";
import BreadCrumbs from '../../components/BreadCrumbs';

const RestoDetails = () => {
  const { contenus,restos } = useContext(DataContext);
  const content = contenus.reduce((acc, item)=>{
    acc[item.id] = item;
    return acc;
  }, {});
  //State:
  const [src, setSrc] = useState(null);
  const navigate = useNavigate();
  //load each:
  const { id } = useParams();
  //resto data
  let resto = null;
  if (restos.length) {
    resto = restos.find((resto) => {
      return resto.id === Number(id);
    });
  }
  // Utilisation de la variable `defaultPhoto` pour initialiser `src`
  useEffect(() => {
    if (resto) {
     
      if (defaultPhoto) {
        setSrc(defaultPhoto);
      }
    }
  }, [resto]);
  //destructuring::
  const { name, menu_url, description, carte_url, boissons_url, restoPhoto } = resto;
  //Photos::
  const detailsItems = restoPhoto.map((photo, index) => ({
    id: index,
    name: photo.title,
    img: photo.image,
    default: photo.default,
  }));
  const defaultPhotoItem = detailsItems.find((photo) => photo.default);
  //defaul Image::
  const defaultPhoto = defaultPhotoItem ? defaultPhotoItem.img : null;
  //methode::
  const hanadlerClick = (i)=>{
    const selectedPhoto = detailsItems.find((photo) => photo.id === i);
    setSrc(selectedPhoto ? selectedPhoto.img : null);
}; 



  return (
    <Fragment>
      <ScrollToTop />
      <header>
        <div className="banner-config" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url(${defaultPhoto})` }}>
          <div className="auto-container flex justify-center items-center w-full h-full ">
            {/* Title Cover*/}
            <div className="text-white text-center font-medium">
              <h1 className="title-details font-playfair">{content[31].texte}</h1>
            </div>
          </div>
        </div>
      </header>
      <div className='auto-container' >
      <BreadCrumbs/>
      </div>
      <section className="py-10 font-poppins gray-background">
        <div className="max-w-6xl px-4 mx-auto ">
          <div className="box-responsive h-full pb-10 bg-white card-shadow">
            {/* Images */}
            <div className="p-3 pb-8">
              <div className="h-[460px] overflow-hidden">
                <img src={src || API_URL_STATIC + defaultPhoto} className="img-content" />
              </div>
             {/* Gallerie Images */}
                <div className="flex flex-wrap">
                  {detailsItems.map((restoDetail)=>(
                    <div className='w-4/12 h-[100px] p-2' key={restoDetail.id} >
                        <img src={API_URL_STATIC + restoDetail.img}
                            alt={restoDetail.name}
                            className="img-default"
                            onClick={() => {
                              hanadlerClick(restoDetail.id);
                            }} />
                    </div>
                  ))}
                </div>
            </div>

            {/* information text */}
            <div className="p-3 max-md:mt-10 ">
              <div className="container mx-auto ">
              <div className='py-2'>
                <div className="float-right">
                    <Button onClick={()=> navigate(-1)}  size='sm' variant="outlined" 
                      className="flex items-center gap-2 normal-case hover:bg-red-700 hover:text-white rounded-none">
                      <BsArrowLeft className='h-5 w-5' /> Go Back
                    </Button>
                  </div>
                    <Typography variant="h2">{name}</Typography>
              </div>    
                {/* DESCRIPTION */}
                <div className="mt-5 border-y py-2 ">
                  <Typography variant="paragraph" color="gray">
                    {ReactHtmlParser(description)}
                  </Typography>
                </div>
                {/* CARTE MENU */}
               { menu_url && carte_url && boissons_url !== null ?
                (
                  <div className='mt-5'>
                  <h1 className="mt-3 text-gray-900 text-3xl text-center font-semibold ">
                    Decouvrez Nos Menus
                  </h1>
                  <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 gap-2">

                  <div className="border p-3 h-full">
                    <div className="flex items-center justify-center gap-x-3 flex-1">
                      <div className="text-3xl text-accent">
                        <Menu />
                      </div>
                      <div className="text-base">Menu Resto</div>
                    </div>

                    <div className='mt-5 text-center'>
                      <a href={`${API_URL_STATIC + carte_url}`} target='_blank'>
                          <button className='text-white p-3 btn-red'>Voir la carte</button> 
                      </a>
                    </div>
                  </div>

                  <div className="border py-3 h-full">
                    <div className="flex items-center justify-center gap-x-3 flex-1">
                      <div className="text-3xl text-accent">
                        <Boissons />
                      </div>
                      <div className="text-base">Boissons</div>
                    </div>
                    
                    <div className='mt-5 text-center'>
                      <a href={API_URL_STATIC + boissons_url} target='_blank'>
                          <button className='text-white p-3 btn-red' >Voir la carte</button> 
                      </a>
                    </div>
                  </div>

                  <div className="border p-3 h-full">
                    <div className="flex items-center justify-center gap-x-3 flex-1">
                      <div className="text-3xl text-accent">
                        <Plat />
                      </div>
                      <div className="text-base">Plat du jour</div>
                    </div>
                    <div className='mt-5 text-center'>
                      <a href={API_URL_STATIC+menu_url} target='_blank'>
                          <button className='text-white p-3 btn-red'>Voir la carte</button> 
                      </a>
                    </div>
                  </div>
                  </div>
                  </div>
                ) : null
                } 
                <div className='mt-20 w-full h-full '>
                  <div className='py-3 lg:float-right '>
                  <Link to={'/contact'}>
                      <button className="max-md:mx-auto  py-5 px-12 capitalize font-semibold mt-10 btn bg-rose-700 hover:bg-rose-600 duration-300">
                          Contact
                      </button>
                  </Link>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default RestoDetails;