import React, {useContext} from 'react';
// headless ui menu
import {Menu} from '@headlessui/react';
//icon
import { BsChevronDown } from 'react-icons/bs';

const lis =[
  {name:'0 kid'},
  {name:'1 kid'},
  {name:'2 kids'},
  {name:'3 kids'},
  {name:'4 kids'},
]

const KidsDropdown = ({kids, setKids}) => {
  
  return ( 
  <Menu as='div' className='w-full h-full bg-white relative' >
    {/* btn */}
    <Menu.Button className='w-full h-full flex items-center justify-between px-8' >
     {kids === '0 Kids' ? 'No Kids' : kids }
      <BsChevronDown className='text-rose-700 text-base' />
    </Menu.Button>
    {/* items */}
    <Menu.Items className="bg-white absolute w-full flex flex-col z-40" as='ul' >
      {lis.map((li , index)=>{
        return (
          <Menu.Item  onClick={ () => setKids(li.name) } as='li' className="border-b last-of-type:border-b-0 h-12 hover:bg-rose-700 hover:text-white flex justify-center items-center cursor-pointer " key={index} >
            {li.name}
          </Menu.Item>
        )
      })}
    </Menu.Items>
  </Menu> 
  );
};

export default KidsDropdown;
