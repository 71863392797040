import React from 'react';
import { Link } from 'react-router-dom';

const NavLinks = ({contenus}) => {

  const content = contenus.reduce((acc, item)=>{
    acc[item.id] = item;
    return acc;
  }, {});

 const links = [
    {
      name: content[2].texte,
      route: "/hebergement",
      submenu: true,
      sublinks: [
        {
          sublink: [
            { name: "Suite de Luxe", link: "/hebergement/room/6" },
            { name: "Chambre Cozy", link: "/hebergement/room/3" },
            { name: "Suite Vintage", link: "/hebergement/room/4" },
          ],
        },
      ],
    },
    {
      name: content[3].texte,
      route: "/restaurant",
      submenu: true,
      sublinks: [
        {
          sublink: [
            { name: "Privilège", link: "restaurant/Privilège/1" },
            { name: "Le Club", link: "/restaurant/LeClub/2" },
            { name: "La Piscine", link: "restaurant/LaPiscine/3", },
          ],
        },
      ],
    },
    {
      name: content[4].texte,
      route: "/evenement",
      submenu: true,
      sublinks: [
        {
          sublink: [
            { name: "Mariage",link: "/evenement/Mariage/1",},
            { name: "Réunions", link: "/evenement/Séminaire/3" },
            { name: "Banquets", link: "/evenement/Banquets/2" },
          ],
        },
      ],
    },
    { name: content[6].texte, route: "/contact" },
  ];
 
  return (
    <>
      {links.map((link, index) => (
        <div >
          <div className="px-3 max-md:py-7 max-md:border-b max-md:text-lg text-left md:cursor-pointer group">
            <Link key={index} to={link.route} className="py-7 line-effect">{link.name}</Link>
            {link.submenu &&  (
              <div className='' >
                  <div className='absolute hidden group-hover:block' >
                    <div className='transtion-all duration-500'>
                      <div className='invisibles p-5' ></div>
                      {
                        link.sublinks.map((mysublinks,index)=>(
                          <div key={index} className='bg-[#ffff] shadow-lg' >
                            {mysublinks.sublink.map((slink)=>(
                              <li  className='cursor-pointer px-8 py-3 border-b'>
                                <Link to={slink.link} >{slink.name}</Link>
                              </li>
                        ))}
                          </div>
                        ))
                      }
                    </div>
                  </div>
             </div>)
             }
          </div>
        </div>
      ))} 
    </>
  );
};

export default NavLinks