import React, {useState,useContext} from 'react';
import { DataContext } from '../../context/DataContext';
import { Link } from 'react-router-dom';
import LogoAnkerana from '../../assets/static/img/logo-400x100.png';
//Components
import NavLinks from './NavLinks';
import MobileNav  from './MobileNav';
import SocialNetwork from '../Items/SocialNetwork';


const NavBar = () => {
  const {contenus} = useContext(DataContext);
  const [open , setOpen] = useState(false);
  const content = contenus.reduce((acc, item)=>{
    acc[item.id] = item;
    return acc;
  }, {});
 
  return (
    <nav className="bg-white w-full sticky top-0 z-50 shadow-2xl">
      {/* Deskteop Nav */}
      <div className="flex items-center font-medium justify-around">
        <div className="z-30 p-5 md:w-auto w-full flex justify-between">
          <div className="">
            <Link to={"/"}>
              <img
                src={LogoAnkerana}
                alt=""
                className="w-full h-14 object-cover md:cursor-pointer"
              />
            </Link>
          </div>
          {/* Mobil Nav button */}
          <div
            className="md:hidden text-3xl"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
          </div>
        </div>
        <ul className="md:flex hidden  items-center gap-8">
          <li>
            <Link
              to={`/`}
              className="py-7 px-3 inline-block transition line-effect"
            >
              {content[1].texte}
            </Link>
          </li>
          <NavLinks contenus={contenus} />
        </ul>
        <div className="md:block hidden" >
          <SocialNetwork/>
        </div>
      </div>

      {/*Mobile nav*/}
      <div
        className={`z-50 absolute w-full h-full  duration-500 ${
          open ? "right-0" : "right-[100%]"
        } `}
      >
        <MobileNav contenus={contenus}/>
      </div>
    </nav>
  );
}

export default NavBar