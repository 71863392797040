import React, {Fragment,useContext, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
//Context
import { DataContext } from '../context/DataContext';
//Commpents
import BookForm from '../components/Forms/BookForm'
import HeadSlide from '../components/Header/HeadSlide';
import GalleryBox from '../components/SectionContent/GalleryBox';
//image
import residence from '../assets/static/img/residence.jpg'


const Home = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const {contenus,restos,rooms,facilities,events} = useContext(DataContext);
  const content = contenus.reduce((acc, item)=>{
    acc[item.id] = item;
    return acc;
  }, {});
//Intro Section :
  const homeIntroFirstImg = residence;
  //texte & Class
  const homeIntroFirstTitle = content[10].texte;
  const IntroFirstTitleClass = content[10].classe;

  const homeIntroSecondTitle = content[11].texte;
  const IntroSecondTitleClass = content[11].classe;

  const homeIntroPostTitle = content[12].texte;
  const IntroPostTitleClass = content[12].classe;

  const homeIntroPostParagh = content[13].texte;
  const IntroPostParaghClass = content[13].classe;

  //BookForm Redirect ResultRoom:
  const navigate = useNavigate();
  const searchRooms = ({ result, startDate, endDate, adults, children }) => {
    navigate("/hebergement/categorie-chambres", {
      state: { roomsPost: result, startDate, endDate, adults, children },
    });
  };

  return (
    <Fragment>
      {/* HEAD PAGE */}
      <HeadSlide contenus={contenus} restos={restos} rooms={rooms}  />
      {/* Reservation Button */}
      <div className="container mx-auto relative">
          <div className="bg-accent/20 mt-4 p-4 lg:shadow-xl lg:absolute lg:left-0 lg:right-0 lg:p-0 lg:z-30 lg:-top-10">
            <BookForm searchRooms={searchRooms} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
          </div>
      </div>
      {/* Intro */}
      <section className="h-full py-24">
        <div className="auto-container">
          <div className="grid grid-col-1 lg:grid-cols-2 lg:h-[500px] ">           
            <div className="shadow-xl w-full h-full overflow-hidden lg:relative lg:z-4 ">
              <img src={homeIntroFirstImg} className="object-cover h-full hover:scale-110 trasition-all duration-300 "/>
            </div>
            <div className="lg:py-8 lg:w-[700px] lg:mt-3 lg:relative lg:right-16 lg:z-5">
              {/* TEXT BOX */}
              <div className="bg-white shadow-xl text-box w-full h-full">
                <div className="leading-snug pb-3">
                  <h1 className={`${IntroFirstTitleClass} font-playfair max-md:text-4xl`}>
                    {homeIntroFirstTitle}
                  </h1>
                  <h2 className={`${IntroSecondTitleClass} font-playfair lg:mt-5 text-rose-700 max-md:text-4xl `}>
                    {homeIntroSecondTitle}
                  </h2>
                </div>

                <div className={`${IntroPostTitleClass} lg:mt-3 font-medium`}>{homeIntroPostTitle}</div>
                  <div className="py-5">
                    <p className={`${IntroPostParaghClass} max-md:text-base `}>
                      {homeIntroPostParagh}
                    </p>
                  </div>
                <div className="">
                  <Link className="" to={"/contact"}>
                    <button className="max-md:text-sm lg:uppercase py-4 btn btn-primary bg-rose-700 shadow-lg hover:bg-rose-600">
                      nous contacter
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* LISTE DES SERVICES */}
      <section className="bg-[#f3f4f6] py-8 pb-48 ">
        <GalleryBox content={content} restos={restos} rooms={rooms} facilities={facilities} events={events}/>
      </section>
    </Fragment>
  );
};

export default Home;
