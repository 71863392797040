import React, {useRef} from 'react';


const IntroHead = ({data, myRef}) => {
    const {introImg, introSubTitle, introTitle, subTitle, introParagh,} = data;

    const scrollClick = () => {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    };
  return (
    <section className="h-full py-24">
        <div className="auto-container">
          <div className="grid grid-col-1 lg:grid-cols-2 lg:h-[500px] ">
            <div className="shadow-xl w-full h-full overflow-hidden lg:relative lg:z-4 ">
              <img
                src={introImg}
                className="object-cover h-full hover:scale-110 trasition-all duration-300 "
              />
            </div>
            <div className="lg:py-8 lg:w-[700px] lg:mt-3 lg:relative lg:right-16 lg:z-5">
              {/* TEXT BOX */}
              <div className="bg-white shadow-xl lg:p-20 p-8 w-full h-full">
                <div className="leading-snug pb-3">
                  <h2 className="font-playfair text-4xl lg:title-text">
                    {introSubTitle}
                  </h2>
                  <h1 className="font-playfair lg:mt-5 text-rose-700 text-4xl  lg:title-text">
                    {introTitle}
                  </h1>
                </div>
                <div className="text-xl lg:mt-3 font-medium">
                  {subTitle}
                </div>
                <div className="py-5">
                  <p className="text-gray-500 text-base lg:text-[18px] ">
                    {introParagh}
                  </p>
                </div>
                <div className="">                
                    <button onClick={scrollClick} className="max-md:text-sm lg:uppercase py-4 btn btn-primary bg-rose-700 shadow-lg hover:bg-rose-600">
                      Voir
                    </button>                
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}

export default IntroHead