import React, {Fragment, useContext, useState, useEffect}  from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Hooks:
import { DataContext } from '../context/DataContext';
import { API_URL } from '../context/DataContext';
//Component
import HeadSlide from '../components/Header/HeadSlide';
import { motion } from "framer-motion";
import GoogleMap from '../components/Items/GoogleMap';
import BreadCrumbs from '../components/BreadCrumbs';

const Contact = () => {
  const {contenus,restos,rooms} = useContext(DataContext);
  const [statusMessage, setStatusMessage] = useState("");
  const [saveMessage, setSaveMessage] = useState("");

  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");
  
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

   // Récupérer le jeton CSRF depuis le cookie envoyé par le serveur
   useEffect(() => {
    const csrftoken = getCookie("csrftoken");
    axios.defaults.headers.post["X-CSRFToken"] = csrftoken;
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

     // Vérification du format de l'adress mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!emailRegex.test(email)) {
      toast.error(
        "L'adresse e-mail est invalide. Veuillez saisir une adresse e-mail valide."
      );
      setSubmitting(false);
      return;
    }
    // Vérification du format du téléphone
    // const phoneRegex = /^\(\d{2}\)\d{9}$/;
    const phoneRegex = /^\+?261[2368]\d{8}$/;
    if (!phoneRegex.test(telephone)) {
      toast.error(
        "Le format du téléphone est incorrect. Veuillez utiliser le format (33)600112233."
      );
      setSubmitting(false);
      return;
    }

    const formData = {
      nom: nom,
      prenom: prenom,
      email: email,
      telephone: telephone,
      message: message,
    };

    try {
      const response = await axios.post(API_URL + "/api/send_email/", formData);
      setSaveMessage("Votre message a bien été enregistré.");
      setSuccess(true);

      if (response.status === 200) {
        setStatusMessage(
         "Votre message a bien été transmis à notre responsable."
        );
        setNom("");
        setPrenom("");
        setEmail("");
        setTelephone("");
        setMessage("");
      } else {
        setStatusMessage(
          "Votre message est enregistré mais n'a pas pu être transmis."
        );
      }
    } catch (error) {
      setStatusMessage(
    "Votre message est enregistré mais n'a pas pu être transmis."
      );
    }
    setSubmitting(false);
  };
  // Fonction utilitaire pour récupérer le jeton CSRF depuis le cookie
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  
  return (
    <Fragment>
      <HeadSlide contenus={contenus} restos={restos} rooms={rooms} />
      <div className='auto-container' >
      <BreadCrumbs/>
      </div>
      <section className="min-h-screen bg-[#f3f4f6] dark:bg-gray-900">
        <div className="container px-6 py-10 mx-auto">
          <motion.div
            className="lg:flex lg:items-center pb-6 lg:-mx-10 bg-white shadow-xl"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <div className="lg:w-1/2 lg:mx-10 max-md:p-5">
              <h1 className="py-3 text-2xl font-semibold text-gray-800 capitalize dark:text-white lg:text-3xl">
                Nous Contacter
              </h1>

              <p className="mt-4 text-gray-500 dark:text-gray-400">
                Ask us everything and we would love to hear from you
              </p>

              {/*informer l'utilisateur du succès ou de l'échec de l'envoi de l'email*/}
              {statusMessage && (
                <div className="mt-4 text-center text-green-600 dark:text-green-400">
                  {statusMessage}
                </div>
              )}

              <form className="mt-12" onSubmit={handleSubmit}>
                <div className="-mx-2 md:items-center md:flex">
                  <div className="flex flex-wrap">
                    <div className="flex-1s px-2">
                      <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                        Nom :
                      </label>
                      <input
                        type="text"
                        value={nom}
                        placeholder="nom..."
                        name="nom"
                        onChange={(e) => {
                          setNom(e.target.value);
                          setStatusMessage("");
                          setSaveMessage("");
                        }}
                        className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                    </div>

                    <div className="lg:flex-1 px-2">
                      <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                        Prénom :
                      </label>
                      <input
                        type="text"
                        placeholder="prenom..."
                        name="prénom"
                        value={prenom}
                        onChange={(e) => {
                          setPrenom(e.target.value);
                          setStatusMessage("");
                          setSaveMessage("");
                        }}
                        className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                    </div>

                    <div className="lg:w-full px-2 mt-4 md:mt-2">
                      <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                        Téléphone :
                      </label>
                      <input
                        type="number"
                        placeholder="Votre numero de telephone..."
                        name="telephone"
                        value={telephone}
                        onChange={(e) => {
                          setTelephone(e.target.value);
                          setStatusMessage("");
                          setSaveMessage("");
                        }}
                        className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                      <ToastContainer />
                    </div>

                    <div className="lg:w-full px-2 mt-4 md:mt-1">
                      <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                        Email :
                      </label>
                      <input
                        type="email"
                        placeholder="Email@example.com"
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setStatusMessage("");
                          setSaveMessage("");
                        }}
                        className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full mt-4">
                  <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                    Message
                  </label>
                  <textarea
                    className="block w-full h-32 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-56 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    placeholder="Message"
                    name="message"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setStatusMessage("");
                      setSaveMessage("");
                    }}
                  ></textarea>
                </div>

                <button
                  type="submit"
                  disabled={submitting}
                  className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-rose-700 rounded-md hover:bg-rose-500 focus:outline-none focus:ring focus:ring-blue-700 focus:ring-opacity-50"
                >
                  {submitting ? "Envoi en cours..." : "ENREGISTRER LE MESSAGE"}
                </button>
                {success && (
                  <div className="mt-4 text-center text-green-500">
                    {saveMessage}
                  </div>
                )}
              </form>
            </div>

            <div className="mx-auto max-md:hidden mt-12 lg:flex lg:mt-0 lg:flex-col lg:items-center lg:w-1/2 lg:mx-10">
 
               <GoogleMap />
               {/* Information contact */}
              <div className="w-full  mt-6 space-y-4 md:mt-8 pb-3 ">
                <p className="flex items-start -mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mx-2 text-rose-700 dark:text-blue-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>

                  <span className="w-full mx-2 text-gray-700 lg:truncate+  dark:text-gray-400">
                    Ankerana , Antananarivo 101 , Madagascar
                  </span>
                </p>

                <p className="flex items-start -mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mx-2 text-rose-700 dark:text-blue-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>

                  <span className="mx-2 text-gray-700 truncate w-72 dark:text-gray-400">
                    (261) 34 01 807 70
                  </span>
                </p>

                <p className="flex items-start -mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mx-2 text-rose-700 dark:text-blue-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>

                  <span className="mx-2 text-gray-700 truncate w-72 dark:text-gray-400">
                    hotel_laresidence@freenet.mg
                  </span>
                </p>
              </div>
                {/*Social Network
              <div className="w-full mt-6  md:mt-8 ">
                <h3 className="text-gray-600 dark:text-gray-300 ">Follow us</h3>

                <div className="flex mt-4 -mx-1.5 ">

                  <a
                    className="mx-1.5 dark:hover:text-blue-400 text-gray-400 transition-colors duration-300 transform hover:text-blue-500"
                    href="https://www.facebook.com/LaResidenceAntananarivo"
                    target='_blank'
                  >
                    <svg
                      className="w-8 h-8"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 10.2222V13.7778H9.66667V20H13.2222V13.7778H15.8889L16.7778 10.2222H13.2222V8.44444C13.2222 8.2087 13.3159 7.9826 13.4826 7.81591C13.6493 7.64921 13.8754 7.55556 14.1111 7.55556H16.7778V4H14.1111C12.9324 4 11.8019 4.46825 10.9684 5.30175C10.1349 6.13524 9.66667 7.2657 9.66667 8.44444V10.2222H7Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>

                  <a
                    className="mx-1.5 dark:hover:text-blue-400 text-gray-400 transition-colors duration-300 transform hover:text-blue-500"
                    href="https://instagram.com/laresidence_ankerana?igshid=NTc4MTIwNjQ2YQ=="
                    target='_blank'
                  >
                    <svg
                      className="w-8 h-8"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.9294 7.72275C9.65868 7.72275 7.82715 9.55428 7.82715 11.825C7.82715 14.0956 9.65868 15.9271 11.9294 15.9271C14.2 15.9271 16.0316 14.0956 16.0316 11.825C16.0316 9.55428 14.2 7.72275 11.9294 7.72275ZM11.9294 14.4919C10.462 14.4919 9.26239 13.2959 9.26239 11.825C9.26239 10.354 10.4584 9.15799 11.9294 9.15799C13.4003 9.15799 14.5963 10.354 14.5963 11.825C14.5963 13.2959 13.3967 14.4919 11.9294 14.4919ZM17.1562 7.55495C17.1562 8.08692 16.7277 8.51178 16.1994 8.51178C15.6674 8.51178 15.2425 8.08335 15.2425 7.55495C15.2425 7.02656 15.671 6.59813 16.1994 6.59813C16.7277 6.59813 17.1562 7.02656 17.1562 7.55495ZM19.8731 8.52606C19.8124 7.24434 19.5197 6.10901 18.5807 5.17361C17.6453 4.23821 16.51 3.94545 15.2282 3.88118C13.9073 3.80621 9.94787 3.80621 8.62689 3.88118C7.34874 3.94188 6.21341 4.23464 5.27444 5.17004C4.33547 6.10544 4.04628 7.24077 3.98201 8.52249C3.90704 9.84347 3.90704 13.8029 3.98201 15.1238C4.04271 16.4056 4.33547 17.5409 5.27444 18.4763C6.21341 19.4117 7.34517 19.7045 8.62689 19.7687C9.94787 19.8437 13.9073 19.8437 15.2282 19.7687C16.51 19.708 17.6453 19.4153 18.5807 18.4763C19.5161 17.5409 19.8089 16.4056 19.8731 15.1238C19.9481 13.8029 19.9481 9.84704 19.8731 8.52606ZM18.1665 16.5412C17.8881 17.241 17.349 17.7801 16.6456 18.0621C15.5924 18.4799 13.0932 18.3835 11.9294 18.3835C10.7655 18.3835 8.26272 18.4763 7.21307 18.0621C6.51331 17.7837 5.9742 17.2446 5.69215 16.5412C5.27444 15.488 5.37083 12.9888 5.37083 11.825C5.37083 10.6611 5.27801 8.15832 5.69215 7.10867C5.97063 6.40891 6.50974 5.8698 7.21307 5.58775C8.26629 5.17004 10.7655 5.26643 11.9294 5.26643C13.0932 5.26643 15.596 5.17361 16.6456 5.58775C17.3454 5.86623 17.8845 6.40534 18.1665 7.10867C18.5843 8.16189 18.4879 10.6611 18.4879 11.825C18.4879 12.9888 18.5843 15.4916 18.1665 16.5412Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
              </div> */}
            </div>
          </motion.div>
        </div>
      </section>
     
    </Fragment>
  );
}

export default Contact;